<template>
    <v-row no-gutters>
        <v-col cols="12">
            <BrandBar :accountId="$route.params.accountId" :brandId="$route.query.brand_id" :brand="brand" class="mb-6"></BrandBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Review Published Assets</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <ImageList @selected="onSelectImage"/> -->
                    <!-- TODO: brand record, images, palettes -->
                    <v-select :items="searchTypeChoices" v-model="searchType" label="Asset type" @change="search" outlined dense/>

                    <template v-if="searchType === 'brand' && Array.isArray(list)">
                    <p class="text-overline mb-0 mt-8">Brands</p>
                    <v-list>
                        <v-list-item v-for="(item, idx) in list" :key="idx" @click="onClickBrand(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }} ({{item.alias}})</v-list-item-title>
                                <!-- <v-list-item-subtitle>Brand {{ item.brand_id }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <!-- {{ JSON.stringify(this.list) }} -->
                    </template>

                    <template v-if="searchType === 'brand_image' && Array.isArray(list)">
                    <p class="text-overline mb-0 mt-8">Brand Images</p>
                    <v-list>
                        <v-list-item v-for="(item, idx) in list" :key="idx" @click="onClickImage(item)">
                            <v-list-item-content>
                                <v-list-item-title>Intent {{ item.intent }} mode {{ item.mode }}</v-list-item-title>
                                <!-- <v-list-item-subtitle>Image {{ item.image_id }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <!-- {{ JSON.stringify(this.list) }} -->
                    </template>

                    <template v-if="searchType === 'brand_palette' && Array.isArray(list)">
                    <p class="text-overline mb-0 mt-8">Brand Palettes</p>
                    <v-list>
                        <v-list-item v-for="(item, idx) in list" :key="idx" @click="onClickPalette(item)">
                            <v-list-item-content>
                                <v-list-item-title>Mode {{ item.mode }}</v-list-item-title>
                                <!-- <v-list-item-subtitle>Palette {{ item.palette_id }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <!-- {{ JSON.stringify(this.list) }} -->
                    </template>

                    <v-dialog v-model="previewDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">{{ previewTypeDisplayName }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon class="green--text" :to="previewEditLink">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <template v-if="previewType === 'brand'">
                                <v-simple-table dense class="mt-8">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Attribute</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{{ previewItem.name }}</td>
                                            </tr>
                                            <tr>
                                                <td>Alias</td>
                                                <td>{{ previewItem.alias }}</td>
                                            </tr>
                                            <tr>
                                                <td>Etag</td>
                                                <td>{{ previewItem.etag }}</td>
                                            </tr>
                                            <tr>
                                                <td>Brand ID</td>
                                                <td>{{ previewItem.brand_id }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not Before</td>
                                                <td>{{ previewItemNotBeforeDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not After</td>
                                                <td>{{ previewItemNotAfterDisplay }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                            <template v-if="previewType === 'brand_image'">
                                <!-- TODO: load image for preview -->

                                <v-simple-table dense class="mt-8">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Attribute</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Intent</td>
                                                <td>{{ previewItemIntentDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Mode</td>
                                                <td>{{ previewItemModeDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Etag</td>
                                                <td>{{ previewItem.etag }}</td>
                                            </tr>
                                            <tr>
                                                <td>Image ID</td>
                                                <td>{{ previewItem.image_id }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not Before</td>
                                                <td>{{ previewItemNotBeforeDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not After</td>
                                                <td>{{ previewItemNotAfterDisplay }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                            <template v-if="previewType === 'brand_palette'">
                                <!-- TODO: palette preview; maybe show some of the colors as circles? like the color list when editing it, but more dense and not editable -->
                                <v-simple-table dense class="mt-8">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Attribute</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Mode</td>
                                                <td>{{ previewItemModeDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Etag</td>
                                                <td>{{ previewItem.etag }}</td>
                                            </tr>
                                            <tr>
                                                <td>Palette ID</td>
                                                <td>{{ previewItem.palette_id }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not Before</td>
                                                <td>{{ previewItemNotBeforeDisplay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Not After</td>
                                                <td>{{ previewItemNotAfterDisplay }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn elevation="4" class="green white--text" @click="saveEditBrandName" :disabled="!isEditBrandNameComplete">
                                <span>Save</span>
                            </v-btn> -->
                            <v-btn text color="grey" @click="previewDialog = false">
                                <span>Close</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
// import ImageList from '@/components/account-dashboard/ImageList.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import BrandBar from '@/components/account-dashboard/BrandBar.vue';

export default {
    components: {
        // ImageList,
        AccessDeniedOverlay,
        BrandBar,
    },
    data: () => ({
        account: null,
        brand: null,
        error: null,
        forbiddenError: false,
        searchType: null,
        list: null,
        searchTypeChoices: [
            { text: 'Brands', value: 'brand' },
            { text: 'Brand Images', value: 'brand_image' },
            { text: 'Brand Palettes', value: 'brand_palette' },
        ],
        previewDialog: false,
        previewType: null,
        previewItem: null,
        previewEditLink: null, // object with route descriptor for redirecting user to edit the item
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        previewTypeDisplayName() {
            switch (this.previewType) {
            case 'brand':
                return 'Brand';
            case 'brand_image':
                return 'Brand Image';
            case 'brand_palette':
                return 'Brand Palette';
            default:
                return this.previewType;
            }
        },
        previewItemModeDisplay() {
            switch (this.previewItem?.mode) {
            case 'light':
                return 'Light Mode';
            case 'dark':
                return 'Dark Mode';
            default:
                return this.previewItem?.mode;
            }
        },
        previewItemIntentDisplay() {
            switch (this.previewItem?.intent) {
            case 'logotype':
                return 'Logotype';
            case 'logomark':
                return 'Logomark';
            case 'logo':
                return 'Logo';
            default:
                return this.previewItem?.intent;
            }
        },
        previewItemNotBeforeDisplay() {
            return Number.isInteger(this.previewItem?.not_before) ? new Date(this.previewItem.not_before).toISOString() : '';
        },
        previewItemNotAfterDisplay() {
            return Number.isInteger(this.previewItem?.not_after) ? new Date(this.previewItem.not_after).toISOString() : '';
        },
    },
    watch: {
        $route(newValue, oldValue) {
            if (newValue.query.brand_id !== oldValue.query.brand_id) {
                this.loadBrand(newValue.query.brand_id);
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`SearchForm.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async search() {
            this.list = null;
            switch (this.searchType) {
            case 'brand':
                this.list = await this.loadPublishedAssets({ q: 'brand', brand_id: this.$route.query.brand_id }); // TODO: add search filters above so user can filter results instead of seeing everything
                break;
            case 'brand_image':
                this.list = await this.loadPublishedAssets({ q: 'brand_image', brand_id: this.$route.query.brand_id }); // TODO: add search filters above so user can filter results instead of seeing everything
                break;
            case 'brand_palette':
                this.list = await this.loadPublishedAssets({ q: 'brand_palette', brand_id: this.$route.query.brand_id }); // TODO: add search filters above so user can filter results instead of seeing everything
                break;
            default:
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Unsupported search type' });
            }
        },
        async loadPublishedAssets(match) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPublishedAssets: true });
                const response = await this.$client.account(this.$route.params.accountId).published.search(match);
                console.log(`SearchForm.vue: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    return response.list;
                }
                return [];
            } catch (err) {
                console.error('failed to search published assets', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { loadPublishedAssets: false });
            }
        },
        // onSelectImage({ id }) {
        //     this.$router.push({ name: 'account-edit-image', params: { accountId: this.$route.params.accountId, imageId: id }, query: { brand_id: this.$route.query.brand_id } });
        // },
        onClickBrand(item) {
            this.previewType = 'brand';
            this.previewItem = item;
            this.previewEditLink = { name: 'account-edit-brand', params: { accountId: item.account_id, brandId: item.brand_id } };
            this.previewDialog = true;
            // this.$router.push({ name: 'account-edit-brand', params: { accountId: item.account_id, brandId: item.brand_id } });
        },
        onClickImage(item) {
            this.previewType = 'brand_image';
            this.previewItem = item;
            this.previewEditLink = { name: 'account-edit-image', params: { accountId: item.account_id, imageId: item.image_id }, query: { brand_id: item.brand_id } };
            this.previewDialog = true;
            // this.$router.push({ name: 'account-edit-image', params: { accountId: item.account_id, imageId: item.image_id }, query: { brand_id: item.brand_id } });
        },
        onClickPalette(item) {
            this.previewType = 'brand_palette';
            this.previewItem = item;
            this.previewEditLink = { name: 'account-edit-palette', params: { accountId: item.account_id, paletteId: item.palette_id }, query: { brand_id: item.brand_id } };
            this.previewDialog = true;
            // this.$router.push({ name: 'account-edit-palette', params: { accountId: item.account_id, paletteId: item.palette_id }, query: { brand_id: item.brand_id } });
        },
        async loadBrand(id) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrand: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.get({ id });
                console.log(`loadBrand: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brand = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand');
                }
            } catch (err) {
                console.error('failed to load brand', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrand: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        if (this.$route.query.brand_id) {
            this.loadBrand(this.$route.query.brand_id);
        }
    },
};
</script>
